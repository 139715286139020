<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="cols layout-row">
        <div
          class="col col-1 col-padding layout-column layout-align-start-center"
        >
          <section-title
            :partNo="part.id"
            :partTitle="part.title"
            data-depth="2.5"
            class="text-right parallax-item parallax-relative"
            :show="showTitle"
            :align="'right'"
          ></section-title>
          <info-modal-box
            id="intro-box"
            :show="showInfoBoxes"
            class="parallax-item parallax-relative section-infobox"
            data-depth="2"
            :data="data.boxes.info_1"
          >
          </info-modal-box>
        </div>
        <div
          class="col col-2 layout-align-start-center col-padding layout-column"
        >
          <info-modal-box
            id="box-right"
            :show="showInfoBoxes"
            class="parallax-item parallax-relative section-infobox"
            data-depth="2"
            :data="data.boxes.info_2"
            :wide="true"
          >
          </info-modal-box>
          <lead
            :show="showLead"
            class="parallax-item parallax-relative"
            data-depth="2"
            :textAlign="'left'"
            >{{ data.controls.text }}</lead
          >

          <div
            class="
              diagram-buttons
              layout-row layout-wrap
              parallax-item parallax-flex
              layout-align-center-start
            "
            data-depth="1.5"
          >
            <div
              class="button-wrap layout-column layout-align-start-center"
              v-for="(item, i) in data.controls.items"
              :class="`button-${item.slug}`"
              :key="i"
              :data-item="i"
            >
              <button
                class="btn btn-control btn-round-big pointer"
                :id="item.slug"
                @click="showModal(item.modal)"
                :style="{ backgroundImage: `url(${item.icon})` }"
              ></button>
              <label :for="item.slug" class="trajan">{{ item.title }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <background-video-full
      :id="'LikableCornyKatydid'"
      :show="showBackground"
      :mask="true"
      :maskOpacity="0.95"
      :poster="data.images.poster"
      :timescale="1.5"
    ></background-video-full>
    <div class="absolute container container-ink">
      <ink
        class="absolute"
        :show="showInk"
        :image="data.images.ink"
        :parallaxDepth="1"
        :customTimeline="inkTimeline"
      ></ink>
    </div>
    <div class="container absolute z-index-1">
      <decal
        class="absolute bottom-center"
        :show="showDecal"
        :image="data.images.decal"
        :parallaxDepth="4"
        :caption="caption"
        :customTimeline="timelineLina"
        id="lina"
      ></decal>
    </div>

    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
  </div>
</template>

<script>
import { chapterPart, modalFunctions } from "@/mixins/core.js";

import BackgroundVideoFull from "@/components/ui/BackgroundVideoFull.vue";
import SectionTitle from "@/components/layout/SectionTitle.vue";
import Lead from "@/components/ui/Lead.vue";
import Ink from "@/components/ui/Ink.vue";
import InfoModalBox from "@/components/ui/InfoModalBox.vue";
import Decal from "@/components/ui/Decal.vue";
import ModalMain from "@/modals/ModalMain.vue";

export default {
  name: "Chapter-1-3",
  mixins: [chapterPart, modalFunctions],
  components: {
    BackgroundVideoFull,
    SectionTitle,
    Lead,
    Ink,
    InfoModalBox,
    Decal,
    ModalMain,
  },
  props: ["content"],
  data() {
    return {
      doParallax: true,
      showControls: false,
      timeline: null,
      timescale: 1.5,
      timescaleReverse: 1.5,
      caption: {
        desc: "Lina Lamula: Londolozi’s Village Mother",
        sub: "image:<a href='https://store.londolozi.com/image/75781' target='_blank'> Lina by Elsa Young</a>",
      },
      itemsToShow: [
        ["showDelay", 200],
        ["showBackground", 1300],
        ["showTitle", 300],
        ["showInfoBoxes", 300],
        ["showInk", 0],
        ["showDecal", 350],
        ["showLead", 50],
        ["showControls", 1000],
      ],
      timelineLina: {
        duration: 1.6,
        animations: {
          scale: 0.85,
          autoAlpha: 0,
          y: "+=7%",
          ease: Power3.easeInOut,
        },
      },
      inkTimeline: {
        duration: 1.6,
        animations: {
          scale: 0.6,
          y: "+=7%",
          ease: Power3.easeInOut,
        },
      },
    };
  },
  watch: {
    showControls(val) {
      if (this.timeline) {
        if (val) {
          this.timeline.play().timeScale(this.timescale);
        } else {
          this.timeline.reverse().timeScale(this.timescaleReverse);
        }
      }
    },
  },
  methods: {
    createTimeline() {
      const tl = new TimelineMax({
        paused: true,
      });

      tl.set(
        ".diagram-buttons",
        {
          autoAlpha: 1,
        },
        0
      )
        .staggerFrom(
          ".btn-control",
          0.7,
          {
            y: "+=50",
            autoAlpha: 0,
            ease: Power2.easeInOut,
            scale: 0.9,
          },
          0.07,
          0
        )
        .staggerFrom(
          ".button-wrap label",
          0.7,
          {
            y: "+=50",
            autoAlpha: 0,
            ease: Power2.easeInOut,
            scale: 0.9,
          },
          0.07,
          "0.35"
        );

      this.timeline = tl;
    },
  },
  mounted() {
    this.createTimeline();
  },
  beforeDestroy() {
    if (this.timeline) {
      this.timeline.kill();
    }
  },
};
</script>

<style scoped>
.col-1 {
  max-width: 41.5%;
}
.cols {
  margin-bottom: 3rem;
}
.section-title {
  max-width: none;
  margin: 0 -4rem 3.8rem 0;
  max-width: 5.6em;
  align-self: flex-end;
}
.section-decal {
  margin: 0;
  left: 15.3%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) -0.8rem 0.8rem 1.9rem);
}
#box-right {
  margin-top: 5vh;
}
/deep/ .section-decal img {
  margin-bottom: -3%;
  top: auto !important;
  max-width: 38vw;
}
.part-lead {
  max-width: 38rem;
  margin-top: 2.5rem;
  margin-right: 14%;
}
.diagram-buttons {
  margin: 2rem -8rem 0 0;
  max-width: 34rem;
  opacity: 0;
}
.diagram-buttons .button-wrap {
  padding: 0 1rem 0.5rem;
}
.button-wrap label {
  width: 8.6rem;
  text-align: center;
  line-height: 1.25em;
  padding: 0 0 0.8rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-round-big {
  width: 5.25rem;
  height: 5.25rem;
  background: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  transition: none;
  margin-bottom: 0.8rem;
  background-position: center;
  background-size: 100%;
  background-size: 92%;
  background-repeat: no-repeat;
}

.items-shown .btn-round-big {
  transition: 0.2s ease-out;
}

.desktop .btn-round-big:hover {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
  height: 5.5rem;
  width: 5.5rem;
  margin-bottom: 0.55rem;
}

.container-ink {
  left: 47.25%;
  mix-blend-mode: multiply;
}

.section-ink {
  left: 1%;
  top: 17.5%;
  width: 25.5em;
  max-width: 20vw;
}
/deep/ .section-ink img {
  max-width: 100%;
  opacity: 0.95;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (max-width: 1740px) {
  .section-decal {
    left: 10%;
  }
}
@media screen and (max-width: 1600px) {
  .section-decal {
    left: 9%;
  }
}

@media screen and (max-width: 1440px) {
  .section-decal {
    left: 7.5%;
  }
}

@media screen and (max-width: 1200px) {
  .diagram-buttons .button-wrap {
    padding: 0 0 0.5rem;
  }
  /deep/ .info-box-wide .info-box {
    width: 35em;
  }
  .col-1 {
    padding-right: 0;
    max-width: 39%;
  }
  .section-title {
    margin-right: -2.5rem;
    margin-bottom: 2.7rem;
  }
  .section-decal {
    left: 6%;
  }
  #box-right {
    margin-top: 1vh;
  }
  .part-lead {
    margin-top: 2rem;
  }
  .container-ink {
    left: 45%;
  }
  .diagram-buttons {
    margin: 2rem -11rem 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .section-decal {
    left: 4%;
  }
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 991px) {
  .cols {
    flex-direction: column;
  }
  .cols > * {
    width: 100%;
    max-width: 100%;
    padding: var(--col-padding);
  }
  .col-1 {
    flex-direction: row;
    justify-content: center;
  }
  .col-2 {
    align-items: flex-start;
  }
  .section-title {
    align-self: flex-start;
    margin: 0.378em 0 0.489em;
    margin-right: 3rem;
  }
  .container-ink {
    left: 50%;
  }
  .section-ink {
    max-width: 100%;
    width: 320px;
    top: 14rem;
    left: auto;
    right: 45%;
  }
  /deep/ .section-decal .decal-caption {
    display: none !important;
  }
  .section-decal {
    left: auto;
    right: -1%;
  }
  /deep/ .section-decal img {
    margin-bottom: -2%;
    max-width: 56vw;
  }
  .view-part {
    padding-bottom: 0;
  }
  #box-right {
    margin-top: 0;
  }
  .diagram-buttons {
    margin: 1.5rem 0 0 0;
    max-width: 33rem;
  }
  .part-lead {
    margin-top: 1.5rem;
  }
  .button-wrap label {
    font-family: "din";
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1;
    width: 8.6rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

@media screen and (max-width: 900px) {
  .section-decal {
    right: -15%;
  }
  /deep/ .section-decal img {
    max-width: 64vw;
  }
}

@media screen and (max-width: 767px) {
  #box-right {
    margin-left: -1rem;
  }
  /deep/ .info-box-wide .info-box {
    width: 35em;
  }
  .diagram-buttons {
    max-width: 26rem;
  }
  .section-decal {
    right: -7rem;
  }
  /deep/ .section-decal img {
    max-width: 63vw;
  }
}

@media screen and (max-width: 640px) {
  .view-part {
    padding: calc(4 * var(--button-rem)) 0 calc(3.75 * var(--button-rem));
  }
  .section-title {
    margin-right: 1.6rem;
  }
  #box-right {
    margin-left: -1.5rem;
  }
  .section-ink {
    width: 270px;
    left: 3%;
    right: auto;
  }
  /deep/ .section-decal img {
    max-width: 61vw;
  }
}

@media screen and (max-width: 560px) {
  .col-1 {
    flex-direction: column;
  }
  .section-title {
    align-self: flex-start;
    margin-left: 5%;
  }
  .section-ink {
    width: 240px;
    left: auto;
    right: 5%;
    top: 5.7rem;
    max-width: 40vw;
  }
  /deep/ .info-box-wide .info-box {
    width: 100%;
  }
  #box-right {
    max-width: calc(100% + 1.5rem);
  }
}

@media screen and (max-width: 479px) {
  /deep/ .info-box-wide .info-box {
    width: 21.8em;
  }
  .col-2 {
    align-items: center;
    padding-bottom: 57%;
  }
  #box-right {
    margin-left: 0;
    order: 3;
  }
  .part-lead {
    order: 1;
  }
  .diagram-buttons {
    order: 2;
  }
  .section-ink {
    right: 1%;
    max-width: 39vw;
  }
  .section-decal {
    left: 12%;
  }
  /deep/ .section-decal img {
    width: 74vw;
    max-width: 300px;
    margin-bottom: -1%;
  }
  .part-lead {
    margin: 0.8rem 0 0.4rem;
  }
  .button-wrap label {
    width: 8.37rem;
    padding-left: 0;
    padding-right: 0;
  }
  .button-wrap.button-energy label {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .btn-round-big {
    background-size: 91%;
  }
}

@media screen and (max-width: 420px) {
}
</style>
